import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UnitDetailComponent } from '@app/routes/units/unit-detail/unit-detail.component';
import { Observable } from 'rxjs';

@Injectable()
export class CobrowseSessionGuardService  {

  constructor(public router: Router) {}

  canDeactivate(
    component: UnitDetailComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return !component.isCobrowseConnectable || !component.connectionUrl || !component.ctxCobrowse;
  }
}